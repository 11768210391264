import React from 'react';
import "./nosotros.css";
import img from "../../images/nosotroscosmeticos.jpg";
import icon from "../../images/logo cosmeticos.png";

export const NOSOTROS = (props) => {
  return (
    <div className='container-about' id='container-about'>
        <div className='about-info'>
            <div className='grid-about'>
                <div className='about-img'>
                    <img src={img}></img>
                </div>
                <div className='about-p'>
                    <div className='capa-nosotros-cost'></div>
                    <h2 className='about-title'>¿Quiénes Somos?</h2>
                    <p>Somos una empresa con más de 30 años en el mercado, elaborando conceptos 
                        de productos naturales y de aromaterapia, desarrollando  fórmulas propias 
                        y fórmulas exclusivas para nuestros clientes. Know How basado en estudios 
                        por técnicos franceses en el área de Aromaterapia y Herbolaria.
                    </p>
                    <p>
                        Gran flexibilidad en equipo de producción con una capacidad  actual de 700,000 
                        unidades mensuales (un turno).
                    </p>
                    <p>
                        Contamos con Laboratorio de Desarrollo de Productos Nuevos y 
                        Laboratorio de Control de Calidad.
                    </p>
                    <h2 className='about-title'>Misión</h2>
                    <p>
                        Somos una empresa 100% Mexicana que ofrece el desarrollo de conceptos de vanguardia, 
                        que utiliza materias primas naturales y activos de última tecnología, para nuevos 
                        conceptos de mercado en el área de salud y cuidado personal.
                    </p>
                    <p>
                        Brindamos el mejor trato a cada uno de nuestros clientes, cubriendo sus  necesidades 
                        y ofreciéndoles productos con la más alta calidad para su entera satisfacción 
                        en el servicio.
                    </p>
                </div>
            </div>
        </div>
    </div>
  )
}
