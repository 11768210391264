import React from 'react'
import "./seccion.css";
import icon1 from "../../images/iconos/productos.png";
import icon2 from "../../images/iconos/innovacion.png";
import icon3 from "../../images/iconos/valor agregado.png";
import icon4 from "../../images/iconos/calidad.png";
import icon5 from "../../images/iconos/precio.png";


export const SECCION = (props) => {
  return (
    <>
    <div className='seccion-container' id='seccion-container'>
      <div className='capa'></div>
      <h2 className='secch2'>¿Por Qué Elegirnos?</h2>
      <p className='whateleg'>
          Ofrecemos productos funcionales de la mejor calidad, precio y servicio, ya que la mayoría de 
          nuestros clientes se enfocan a la distribución a través de la Venta Directa, Telemarketing 
          y Punto de Venta en Tiendas de Autoservicio, donde los márgenes deben ser atractivos, y que, 
          a su vez, el consumidor final reciba en todo momento Calidad, Producto Eficaz y Valor agregado
            en cada compra. 
      </p>
      <div className='seccion-cont-grid'>
        <div className='secg1 seccinfo'>
            <img src={icon1} alt=''></img>
            <h2>PRODUCTOS DE VANGUARDIA</h2>
        </div>
        <div className='secg2 seccinfo'>
        <img src={icon3} alt=''></img>
          <h2>VALOR AGREGADO</h2>
        </div>
        <div className='secg3 seccinfo'>
          
          <img src={icon2} alt=''></img>
          <h2>INNOVACIÓN</h2>  
        </div>
        <div className='secg4 seccinfo'>
            <img src={icon4} alt=''></img>
            <h2>CALIDAD</h2>
        </div>
        <div className='secg5 seccinfo'>
          <img src={icon5} alt=''></img>
          <h2>PRECIO</h2>
        </div>
      </div>
      <div className='seccion-cont-grid2'>
        
      </div>
    </div>
    </>
  )
}
