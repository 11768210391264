import React, { useEffect } from 'react'
import "./navbar.css";

import { useState } from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import logocos from "../../images/logo cosmeticos.png";

export const NAVBAR = (props) => {

    const[menu, setmenu]=useState(false);


    useEffect(() => {
        
        if (menu === true) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'visible'; // O 'auto' si prefieres restaurar el scroll
        }
        return () => {
            document.body.style.overflow = 'visible'; // O 'auto' si es necesario
        };
    }, [menu])


    const [ullist, setullist]=useState(false);


  return (
    <div className='navbar_container'>
        <div className='navbar-seccion-grid'>
            <div className='menu-logo'>
                <img src={logocos}></img>
            </div>
            <div className='menu-items'>
                <ul className='ul-items'>
                    <li><a href='#container-about'>{props.ingles===true?"ABOUT US":"NOSOTROS"}</a></li>  
                    <li><a href='#productos-vid'>{props.ingles===true?"PRODUCTS":"PRODUCTOS"}</a></li>
                    <li><a href='#seccion-container'>{props.ingles===true?"TEAM":"¿POR QUÉ ELEGIRNOS?"}</a></li>
                    <li><a href='#contact-container'>{props.ingles===true?"CONTACT":"CONTACTO"}</a></li>
                    {/* <li><a href=''>{props.ingles===true?"CONTACT":"CONTACTO"}</a></li> */}
                    {/* <li>
                        <div className="language-dropdown">
                            {props.ingles===true?"LANGUAGE":"IDIOMA"}<ArrowDropDownIcon></ArrowDropDownIcon>
                            <ul className="language-options">
                                <li>
                                    {
                                        props.ingles===true?
                                        <a href='#' onClick={()=>props.setingles(false)}>ESPAÑOL</a>:
                                        <a href='#' onClick={()=>props.setingles(true)}>INGLÉS</a>
                                    }
                                    
                                </li>
                            </ul>
                        </div>
                    </li> */}
                </ul>
            </div>
            <MenuIcon className='menu-cel' onClick={()=>setmenu(!menu)}></MenuIcon>
            {
            menu===true?    
                <div className='menu-flotante-cel-hi'>
                    <div className='capa'></div>
                    <MenuIcon className='menu-cel' onClick={()=>setmenu(!menu)}></MenuIcon>
                    <ul className='menu-flotante-items'>
                        
                        <li><a href='#container-about' onClick={()=>setmenu(false)}>{props.ingles===true?"ABOUT US":"NOSOTROS"}</a></li>  
                        <li><a href='#productos-vid' onClick={()=>setmenu(false)}>{props.ingles===true?"PRODUCTS":"PRODUCTOS"}</a></li>
                        <li><a href='#seccion-container' onClick={()=>setmenu(false)}>{props.ingles===true?"TEAM":"¿POR QUÉ ELEGIRNOS?"}</a></li>
                        <li><a href='#contact-container' onClick={()=>setmenu(false)}>{props.ingles===true?"CONTACT":"CONTACTO"}</a></li>
                    </ul>
                </div>:
                <></>
            }
        </div>
    </div>
  )
}
