import React from 'react'
import mark1 from "../../images/cosmeticos.jpg";

export const SECCION_B = () => {

  return (
    <>
    <div className='seccion-vid' id='productos-vid'>
      <div className='grid-vid'> 
        <div className='grvid1'>
          <iframe width="95%" height="315" src="https://www.youtube.com/embed/47GaqDhgCyI?si=PtSrX5KPdVTmJ4gv" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        </div>
        <div className='grvid2'>
          <h2>Elaboramos Productos</h2>
          <h2>Que Cuidan De Ti </h2>
          <p>En estos tiempos de contingencia por el covid-19 es necesario 
            utilizar productos que  protejan de ti y tu familia.</p>
          <p>En CNI tenemos los ideales para ti.</p>
        </div>
      </div>
    </div>
    <div className='marketing'>
      <div className='gridm1'>
        <h2>LEGAL</h2>
        <p>
          Estamos registrados ante la Secretaría de Salud como fabricante de productos Cosméticos 
          e Higiene Personal, así como Laboratorio de Remedios Herbolarios y Suplementos Alimenticios.
        </p>
        <p>
          Contamos con GMP (Good Manufacturing Practice) certificada por COFEPRIS.
        </p>
        <p>
          Contamos con registro de marcas así como el alta de productos ante la Secretaría de Salud
        </p>
      </div>
      <div className='gridm2'>
        <img src={mark1} alt=''></img>
      </div>
      
    </div>
    </>
  )
}
