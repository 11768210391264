import React from 'react'
import "./home.css";
import { NAVBAR } from './navbar/navbar';
import { SLIDER } from './slider/slider';
import { SECCION } from './seccion/seccion';
import { NOSOTROS } from './nosotros/nosotros';
import { FOOTERC } from './footer/footer';
import { PROYECTOS } from './project/projects';
import { CONTACT } from './contact/contact';
import { useState } from 'react';

import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { CLIENT } from './client/client';
import { SECCION_B } from './seccion/seccionB';
export const HOME = () => {

  const [ingles, setingles]=useState(false);

  window.onscroll = function() {
    var button = document.getElementById("scrollToTopButton");
    if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
      document.getElementById("scrollToTopButton").classList.remove("active"); 
      button.style.display = "block";
    } else {
      document.getElementById("scrollToTopButton").classList.add("active"); 
      setTimeout(() => {
         button.style.display = "none";
      }, 1000);  
    }
  };

  const scrollToTop = () => {

   const currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
   if (currentScroll > 0) {
     const scrollStep = Math.ceil(currentScroll / 10);      
     window.scrollTo(0, currentScroll - scrollStep);
     requestAnimationFrame(scrollToTop);
   }
 };

 const upfunc = () => {
   scrollToTop();
 };
 

  return (
    <div className='home-container'>
        <NAVBAR ingles={ingles} setingles={setingles}></NAVBAR>
        <SLIDER ingles={ingles}></SLIDER>
        <NOSOTROS ingles={ingles}></NOSOTROS>
        <SECCION_B ingles={ingles}></SECCION_B>
        <SECCION ingles={ingles}></SECCION>
        <CONTACT ingles={ingles}></CONTACT>
        <CLIENT ingles={ingles}></CLIENT>
        <FOOTERC ingles={ingles}></FOOTERC>

        <button id="scrollToTopButton" onClick={()=>upfunc()}
          className='button-up'
        > 
          <ArrowUpwardIcon></ArrowUpwardIcon>
        </button>
    </div>
  )
}
