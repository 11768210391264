import React, { useEffect, useState } from 'react';
import HeroSlider, { Overlay, Slide, MenuNav, Nav, AutoplayButton } from "hero-slider";

import slide from "../../images/slider.jpg";
import "./slider.css";
import logo from "../../images/logoca1.png";

export const SLIDER = (props) => {
    const [loaded, setLoaded] = useState(0);
  
    useEffect(() => {
      const timer = setInterval(() => {
        setLoaded((prevProgress) => prevProgress + 1);
      }, 100); 
  
      if (loaded >= 100) {
        clearInterval(timer);
        setLoaded(0);
      }
      return () => {
        clearInterval(timer);
      };
    }, [loaded]);


  return (
    <div className='container-slider' id='home'>
       <HeroSlider
          // autoplay
          height="100vh"
          
          controller={{
            initialSlide: 1,
            slidingDuration: 1000,
            slidingDelay: 0,
            onSliding: (nextSlide) =>
              console.debug("onSliding(nextSlide): ", nextSlide, setLoaded(0)),
            onBeforeSliding: (previousSlide, nextSlide) =>
              console.debug(
                "onBeforeSliding(previousSlide, nextSlide): ",
                previousSlide,
                nextSlide,
                setLoaded(0)
              ),
            onAfterSliding: (nextSlide) =>
              console.debug("onAfterSliding(nextSlide): ", nextSlide, setLoaded(0))
          }}
      >
      <Slide
        shouldRenderMask
        background={{
          maskBackgroundBlendMode: 'luminosity',
          backgroundImageSrc: slide,
          backgroundAnimation: 'fade'     
        }}
      >
        <Overlay>
           <div className='container-text'>
            {/* <img src={logo} className='logo-ca'></img> */}
            <p className='text-curiel-slogan'>
              "Calidad, servicio y bienestar se unen en cada gota de nuestros productos. Tu elección más saludable."     
            </p>  
          </div> 
        </Overlay>

      </Slide>
    <Nav></Nav>
    </HeroSlider>      
    </div>
  )
}
