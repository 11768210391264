import "./contact.css";
import {useRef, useState} from "react";
import ReCAPTCHA from "react-google-recaptcha";
import FacebookIcon from '@mui/icons-material/Facebook';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import InstagramIcon from '@mui/icons-material/Instagram';
import axios from "axios";

export const CONTACT = () => {

    const captcha = useRef(null);
    const [name, setname] = useState("");
    const [email, setemail] = useState("");
    const [city, setcity] = useState("");
    const [msj, setmsj] = useState("");
    const ingles = false;

    const verificar = () =>{
        if(captcha.current.getValue()){
            console.log("El usuario no es un robot");
        }
    }

    const verificarcorreo =(email)=>{
        const regexCorreo = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        return regexCorreo.test(email);
    }


    const enviarCorreo = ()=> {
        
        if (captcha.current.getValue()) {

            if(name==="" || name?.trim()=="" || email === "" || email?.trim()==="" || msj === ""){
                alert("Complete los campos del formulario");
                return null;
            }           
            const d = verificarcorreo(email);
            if(d===false){
                alert("Correo no válido");
                return null;
            }

            axios.post('https://4d33iqn0g7.execute-api.us-east-1.amazonaws.com/prod/email',
                { 
                    name: name, 
                    email: email,
                    city: city,
                    message: msj,      
                }
            )
            .then(function (res) {
                console.log(res)
                setname("");
                setcity("");
                setemail("");
                setmsj("");
               
            })
            .catch(function (err) {
                console.log(err);
            });

        }else{
            alert("Completa el CAPTCHA antes de enviar el formulario.");
            return;
        }
    }


  return (
    <>
    <div className='contact-container' id="contact-container">
        <div className='capa-contact'></div>
        <div className='contact-grid'>
            <div className='formulario'>
                <h2>{ingles===true?"Contact":"Contacto"}</h2>
                <div className="formulario-inputs">
                    <input placeholder={ingles===true?"Name":'Nombre'} value={name} onChange={(e)=>setname(e.target.value)}></input>
                    <input placeholder={ingles===true?"Email":'Correo Electrónico'} value={email} onChange={(e)=>setemail(e.target.value)}></input>
                    <input placeholder={ingles===true?"City":'Ciudad'} value={city} onChange={(e)=>setcity(e.target.value)}></input>
                    <textarea placeholder={ingles===true?"Message":'Mensaje'} value={msj} onChange={(e)=>setmsj(e.target.value)}></textarea>
                </div>
                <div className='input-captcha'>
                        <ReCAPTCHA
                            ref={captcha}
                            sitekey="6LfUNm4oAAAAAGXAGIJh5DYP1sVjIOKJepXJwe4b"
                            onChange={verificar}
                        />
                </div>
                <div className='button-enviar'>
                    <button onClick={()=>enviarCorreo()}>{ingles===true?"SUBMIIT":"ENVIAR"}</button>
                </div>
                
            </div>
            <div className='mapadir'>
                <h2>Dirección</h2>
                    <div className="dir-map1">
                        <p className='dirmap'> Kinchil Mz.391 Lt. 3, col. Héroes de Padierna, c.p. 14200, Tlalpan, Ciudad de México
                        </p>
                        <iframe loading='lazy' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d476.89781602208103!2d-99.21919994355811!3d19.280735028544807!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85cdfe4c40eaf85b%3A0xcc9db4b5bbbb8cbf!2sCosm%C3%A9ticos%20Naturales%20Ideal%20S.A.%20de%20C.V.!5e0!3m2!1ses-419!2smx!4v1709842197283!5m2!1ses-419!2smx"></iframe>
                    </div>
                <div className="contac-info-cosmt">
                    <h3>¿Necesitas Comunicarte Con Nosotros?</h3>
                    <p>
                        <span>Correo:</span>cosmeticosnaturalesideal@hotmail.com
                    </p>
                    <p>
                        <span>Horario:</span>Lunes a viernes de 9:00 a 17:00 hrs.
                    </p>
                    <p>
                        <span>Teléfono:</span>55 56 44 03 00 con  10 extensiones
                    </p>
                </div>
            </div>
            
        </div>
    </div>
    </>
    
  )
}
