import React from 'react'
import logo1 from "../../images/clientes/c1.jpg";
import logo2 from "../../images/clientes/c2.png";
import logo3 from "../../images/clientes/c3.png";
import logo4 from "../../images/clientes/c4.jpg";
import logo5 from "../../images/clientes/c5.png";
import logo6 from "../../images/clientes/c6.jpg";
import logo7 from "../../images/clientes/c6.png";
import logo8 from "../../images/clientes/c7.png";
import logo9 from "../../images/clientes/c8.jpg";
import logo10 from "../../images/clientes/c9.jpg";
import logo11 from "../../images/clientes/c10.png";
import logo12 from "../../images/clientes/c11.png";
import logo13 from "../../images/clientes/c12.png";
import logo14 from "../../images/clientes/c13.png";
import logo15 from "../../images/clientes/c15.png";
import logo16 from "../../images/clientes/c17.jpg";
import logo17 from "../../images/clientes/c18.jpg";
import logo18 from "../../images/clientes/c19.png";

import "./client.css";

export const CLIENT = (props) => {
  return (
    <div className='client' id='clientback'>
        <div className='capa-dark-ser'></div>
        <div className='about-clients-confi' id='client'>
                  
                  <h3 id="clients" class="clientConf">{props.ingles===true?"CUSTOMERS WHO TRUST US:":"NUESTROS CLIENTES"}</h3>
                    
                    <div class="wrapper">
                      <div className='carrusel'>
                          <div class="item">
                              <img class="imgGenaral logoboll" alt="" src={logo1} />
                          </div>
                          <div class="item">
                              <img class="imgGenaral" alt="" src={logo2} />
                          </div>
                          <div class="item">
                              <img class="imgGenaral logoiaia" alt="" src={logo3} />
                          </div>
                          <div class="item">
                              <img class="imgGenaral" alt="" src={logo4}/>
                          </div>
                          <div class="item">
                              <img class="imgGenaral" alt="" src={logo5}/>
                          </div>
                          <div class="item">
                              <img class="imgGenaral logofc" alt="" src={logo6} />
                          </div>
                          <div class="item">
                              <img class="imgGenaral logoboll" alt="" src={logo7} />
                          </div>
                          <div class="item">
                              <img class="imgGenaral" alt="" src={logo8} />
                          </div>
                          <div class="item">
                              <img class="imgGenaral logoiaia" alt="" src={logo9} />
                          </div>
                          <div class="item">
                              <img class="imgGenaral" alt="" src={logo10}/>
                          </div>
                          <div class="item">
                              <img class="imgGenaral" alt="" src={logo11}/>
                          </div>
                          <div class="item">
                              <img class="imgGenaral logofc" alt="" src={logo12} />
                          </div>  
                          <div class="item">
                              <img class="imgGenaral logofc" alt="" src={logo13} />
                          </div>  
                          <div class="item">
                              <img class="imgGenaral logofc" alt="" src={logo14} />
                          </div>  
                          <div class="item">
                              <img class="imgGenaral logofc" alt="" src={logo15} />
                          </div>  
                          <div class="item">
                              <img class="imgGenaral logofc" alt="" src={logo16} />
                          </div>  
                          <div class="item">
                              <img class="imgGenaral logofc" alt="" src={logo17} />
                          </div>  
                          <div class="item">
                              <img class="imgGenaral logofc" alt="" src={logo18} />
                          </div>  
                          <div class="item">
                              <img class="imgGenaral logoboll" alt="" src={logo1} />
                          </div>
                          <div class="item">
                              <img class="imgGenaral" alt="" src={logo2} />
                          </div>
                          <div class="item">
                              <img class="imgGenaral logoiaia" alt="" src={logo3} />
                          </div>
                          <div class="item">
                              <img class="imgGenaral" alt="" src={logo4}/>
                          </div>
                          <div class="item">
                              <img class="imgGenaral" alt="" src={logo5}/>
                          </div>
                          <div class="item">
                              <img class="imgGenaral logofc" alt="" src={logo6} />
                          </div>
                      </div>  
                    </div>
        </div>
    </div>
  )
}
