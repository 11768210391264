import React from 'react'
import "./footer.css";
import icon from "../../images/logo cosmeticos.png";
import FacebookIcon from '@mui/icons-material/Facebook';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

export const FOOTERC = (props) => {
  return (
    <div className='footer-container'>
        <div className='capa-foot'></div>
        <div className='column-footer'>
            <div className='col1'>
                <img src={icon} alt=''></img>
            </div>
            <div className='col2'>
                <p className='col2dir'>{props.ingles===true?"Address:":"Dirección:"} </p>
                <p className='col2text'>Kinchil Mz.391 Lt. 3 col. Héroes de Padierna Tlalpan, CDMX</p>
                <p className='col2text'>Teléfono: 55 56 44 03 00 con 10 líneas</p>
            </div>
            <div className='col3'>
                <div className='cont-redes'>
                    <p className='col3p'>{props.ingles===true?"Contact:":"Contacto:"}</p>
                    <div className='grid-redes-cosme' onClick={()=>window.open("https://www.facebook.com/cosmeticos.naturalesideal/photos", "_blank")}>
                        <FacebookIcon></FacebookIcon>
                        Facebook
                        {/* <WhatsAppIcon onClick={()=>window.open("https://api.whatsapp.com/send?phone=5556440300", "_blank")}></WhatsAppIcon> */}
                    </div>
                </div>
            </div>
        </div>
        <p className='copyr'>{props.ingles===true?"Group Carco 2015 © All rights reserved":"Cosmeticos Naturales Ideal © Todos los derechos reservados"}</p>
    </div>
  )
}
