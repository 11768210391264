import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import { HOME } from './pages/Home';

function App() {
  return (
    <div>
      <Router>
        <Switch>
          <Route path={"/"} exact component={HOME}></Route>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
